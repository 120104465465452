const GoolgeLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="75"
    height="29"
    fill="none"
  >
    <path
      d="M32.096 14.612c0 3.465-2.779 6.018-6.189 6.018s-6.189-2.553-6.189-6.018c0-3.489 2.779-6.018 6.189-6.018s6.189 2.529 6.189 6.018zm-2.709 0c0-2.165-1.611-3.647-3.48-3.647s-3.48 1.481-3.48 3.647c0 2.144 1.611 3.647 3.48 3.647s3.48-1.506 3.48-3.647z"
      fill="#ea4335"
    />
    <path
      d="M45.447 14.612c0 3.465-2.779 6.018-6.189 6.018s-6.189-2.553-6.189-6.018c0-3.487 2.779-6.018 6.189-6.018s6.189 2.529 6.189 6.018zm-2.709 0c0-2.165-1.611-3.647-3.48-3.647s-3.48 1.481-3.48 3.647c0 2.144 1.611 3.647 3.48 3.647s3.48-1.506 3.48-3.647z"
      fill="#fbbc05"
    />
    <path
      d="M58.241 8.958v10.804c0 4.444-2.687 6.259-5.863 6.259a5.87 5.87 0 0 1-5.468-3.546l2.359-.958c.42.979 1.449 2.135 3.107 2.135 2.033 0 3.293-1.224 3.293-3.527v-.865h-.095c-.606.73-1.775 1.367-3.249 1.367-3.085 0-5.911-2.621-5.911-5.994 0-3.397 2.826-6.04 5.911-6.04 1.471 0 2.64.638 3.249 1.346h.095v-.979h2.573v-.003zm-2.381 5.676c0-2.119-1.449-3.668-3.293-3.668-1.869 0-3.435 1.549-3.435 3.668 0 2.097 1.566 3.625 3.435 3.625 1.844 0 3.293-1.528 3.293-3.625z"
      fill="#4285f4"
    />
    <path d="M62.483 2.625v17.636h-2.642V2.625h2.642z" fill="#34a853" />
    <path
      d="M72.781 16.593l2.103 1.367c-.679.98-2.314 2.667-5.14 2.667-3.505 0-6.122-2.643-6.122-6.018 0-3.579 2.64-6.018 5.819-6.018 3.201 0 4.767 2.485 5.279 3.829l.281.684-8.247 3.332c.631 1.207 1.613 1.823 2.99 1.823s2.337-.662 3.037-1.666zm-6.472-2.165l5.513-2.233c-.303-.752-1.215-1.275-2.289-1.275a3.34 3.34 0 0 0-3.224 3.508z"
      fill="#ea4335"
    />
    <path
      d="M9.716 13.047v-2.553h8.82a8.26 8.26 0 0 1 .131 1.541c0 1.916-.537 4.284-2.267 5.972-1.683 1.709-3.833 2.621-6.681 2.621C4.439 20.628 0 16.433 0 11.283s4.439-9.345 9.719-9.345c2.921 0 5.001 1.118 6.564 2.575l-1.847 1.802c-1.121-1.026-2.64-1.823-4.72-1.823-3.855 0-6.87 3.031-6.87 6.791s3.015 6.791 6.87 6.791c2.501 0 3.925-.98 4.837-1.869.74-.722 1.227-1.753 1.419-3.161l-6.256.003z"
      fill="#4285f4"
    />
  </svg>
);

export default GoolgeLogo;
