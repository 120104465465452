import {FC} from 'react';
import {useRouter} from 'next/router';
import cn from 'classnames';

import {TRouter} from '@local-types/global';

import biasesLogoDescription from '@data/biasesLogoDescription';

import GoogleLogo from '@icons/GoogleLogo';
import GoogleGreyLogo from '@icons/GoogleGreyLogo';

import AmazonLogo from "@icons/AmazonLogo";
import AmazonGreyLogo from "@icons/AmazonGreyLogo";

import XLogo from '@icons/XLogo';
import XGreyLogo from '@icons/XGreyLogo';

import HarvardBusinessSchoolLogo from '@icons/HarvardBusinessSchoolLogo';
import HarvardBusinessSchoolGreyLogo from '@icons/HarvardBusinessSchoolGreyLogo';

import DukeLogo from '@icons/DukeLogo';
import DukeGreyLogo from '@icons/DukeGreyLogo';

import styles from './Logos.module.scss';

type LogoProps = {
  className?: string;
  logoDescriptions?: string;
};
const Logos: FC<LogoProps> = ({className, logoDescriptions}) => {
  const router = useRouter();
  const {locale} = router as TRouter;
  const description = biasesLogoDescription[locale].description;

  return (
    <div
      className={cn(styles.logoWrapper, {
        [className]: !!className,
      })}
    >
      <span className={styles.title}>{description}</span>
      <div className={styles.flexWrapper}>
        <div className={styles.flex1}>
          <div className={styles.logos}>
            <GoogleLogo/>
            <GoogleGreyLogo/>
          </div>
          <div className={styles.logos}>
            <XLogo/>
            <XGreyLogo/>
          </div>
          <div className={styles.logos}>
            <AmazonLogo/>
            <AmazonGreyLogo/>
          </div>
        </div>
        <div className={styles.flex2}>
          <div className={styles.logos}>
            <DukeLogo/>
            <DukeGreyLogo/>
          </div>
          <div className={styles.logos}>
            <HarvardBusinessSchoolLogo/>
            <HarvardBusinessSchoolGreyLogo/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logos;
