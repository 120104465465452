const AmazonGreyLogo = () => (
  <svg width="72" height="28" viewBox="0 0 72 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M44.668 19.234C40.4832 22.3129 34.4208 24.0002 29.1968 24.0002C21.8784 24.0002 15.2856
           21.2866 10.3008 16.6666C9.90884 16.3005 10.2576 15.8601 10.7288 16.1534C16.1104 19.3067
            22.7648 21.2138 29.6392 21.2138C34.276 21.2138 39.372 20.2603 44.0632 18.2076C44.7672 17.9135 45.3592 18.72 44.668 19.234Z"
          fill="#9E9E9E"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M46.4097 17.1802C45.8737 16.52 42.8729 16.8869 41.5233 17.0332C41.1153
           17.1059 41.0513 16.7398 41.4185 16.4465C43.8129 14.7599 47.7385 15.2731 48.1937 
           15.7862C48.6521 16.3729 48.0729 20.407 45.8281 22.3134C45.4825 22.6067 45.1561
            22.3861 45.3081 22.02C45.8145 20.7731 46.9449 17.914 46.4097 17.1802Z"
          fill="#9E9E9E"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M41.616 4.42031V2.73296C41.6192 2.51315 41.804 2.29335 42.032
           2.29335H49.356C49.588 2.29335 49.7792 2.51315 49.7792 2.73296V4.19969C49.776 
           4.42031 49.5776 4.71366 49.2272 5.226L45.4336 10.7277C46.8432 10.6549 48.332 
           10.8739 49.612 11.6077C49.9016 11.754 49.98 12.0473 50.0008 12.2679V14.028C50.0008 
           14.2478 49.7384 14.5412 49.4624 14.3941C47.2072 13.2207 44.2176 13.0744 41.724 
           14.3941C41.4688 14.5412 41.2008 14.2478 41.2008 14.028V12.3407C41.2008 12.0473 41.2064 
           11.6077 41.4744 11.2416L45.8704 4.78719H42.0424C41.8064 4.78719 41.6184 4.64093 41.616
            4.42112V4.42031ZM56.104 4.49304C54.4616 4.49304 54.3568 6.76708 54.3568 8.16027C54.3568 
            9.55347 54.336 12.6332 56.0848 12.6332C57.8128 12.6332 57.8928 10.1402 57.8928 8.67343C57.8928 7.71985 
            57.8504 6.54646 57.5616 5.5937C57.312 4.78639 56.8112 4.49304 56.104 4.49304ZM56.0848 2.14627C59.3936 
            2.14627 61.1808 5.007 61.1808 8.67343C61.1808 12.1936 59.2056 14.98 56.0848 14.98C52.8408 14.98 51.0704
            12.12 51.0704 8.52635C51.0704 4.93346 52.86 2.14627 56.0848 2.14627ZM38.0272 14.7602C37.8792 14.8329 
            37.6112 14.8329 37.4992 14.7602C36.7384 14.1735 36.2208 13.2934 36.2208 13.2934C34.9968 14.5404 34.132
            14.9064 32.5488 14.9064C30.6728 14.9064 29.2128 13.7339 29.2128 11.3871C29.2128 9.55266 30.1936 8.30654 31.5888
            7.71985C32.7968 7.13316 34.488 7.06043 35.7792 6.91335C35.7792 6.91335 35.8856 5.52016 35.508 5.00619C35.2168 4.56658 
            34.716 4.42031 34.2576 4.42031C33.4296 4.42031 32.6312 4.85993 32.4736 5.73996C32.428 5.95977 32.2456 6.18039 32.052
            6.18039L29.9176 5.95977C29.7544 5.88704 29.532 5.73996 29.5856 5.44662C30.084 2.8065 32.4496 2 34.5712 2C35.656 2 37.0728 2.29335 37.9272 
            3.09985C39.012 4.12616 38.908 5.52016 38.908 6.98689V10.507C38.908 11.6061 39.3416 12.0465 39.7488 12.6332C39.8912 12.8538 39.9232 
            13.0736 39.7408 13.2199C39.2856 13.5868 38.0272 14.7602 38.0272 14.7602ZM35.7792 9.18658C35.7792 10.0674 35.8008 10.8004 35.3632
            11.6069C35.0104 12.2671 34.4472 12.6332 33.8208 12.6332C32.9656 12.6332 32.4656 11.9738 32.4656 11.0202C32.4656 9.04031
            34.1688 8.74697 35.7792 8.74697V9.18658ZM8.8136 14.7602C8.6664 14.8329 8.3952 14.8329 8.2888 14.7602C7.528 14.1735 7.0104 13.2934 
            7.0104 13.2934C5.7864 14.5404 4.9208 14.9064 3.3384 14.9064C1.4592 14.9064 0 13.7339 0 11.3871C0 9.55266 0.9808 8.30654 2.376
            7.71985C3.584 7.13316 5.2744 7.06043 6.5656 6.91335C6.5656 6.91335 6.6728 5.52016 6.2952 5.00619C6.0008 4.56658 5.5024 
            4.42031 5.044 4.42031C4.2168 4.42031 3.4184 4.85993 3.26 5.73996C3.2144 5.95977 3.0328 6.18039 2.84 6.18039L0.704 5.95977C0.5408 
            5.88704 0.3184 5.73996 0.372 5.44662C0.8704 2.8065 3.236 2 5.3576 2C6.4424 2 7.86 2.29335 8.7144 3.09985C9.7992 4.12616 
            9.6952 5.52016 9.6952 6.98689V10.507C9.6952 11.6061 10.1288 12.0465 10.536 12.6332C10.6776 12.8538 10.7104 13.0736 10.528 
            13.2199C10.072 13.5868 8.8136 14.7602 8.8136 14.7602ZM6.5656 9.18658C6.5656 10.0674 6.5872 10.8004 6.148 11.6069C5.7944 
            12.2671 5.232 12.6332 4.6048 12.6332C3.7504 12.6332 3.2496 11.9738 3.2496 11.0202C3.2496 9.04031 4.9536 8.74697 6.5632 
            8.74697V9.18658H6.5656ZM15.328 8.16027C15.328 6.83981 15.2672 5.007 16.8688 5.007C18.4496 5.007 18.2408 6.91416 18.2408
            8.16027L18.2432 14.3205C18.2432 14.5404 18.4192 14.7602 18.6424 14.7602H20.868C21.1072 14.7602 21.2968 14.5404 21.2968 
            14.3205V8.16027C21.2968 7.50004 21.276 6.54647 21.5064 5.95977C21.736 5.37308 22.296 5.00619 22.8368 5.00619C23.4832 5.00619 
            23.9808 5.22681 24.148 6.03331C24.252 6.47373 24.2088 7.71985 24.2088 8.16027V14.3205C24.2088 14.5404 24.3848 14.7602 
            24.608 14.7602H26.8344C27.0728 14.7602 27.2624 14.5404 27.2624 14.3205L27.2656 6.98689C27.2656 5.73996 27.4096 
            4.34677 26.7032 3.39319C26.08 2.51315 25.0608 2.07354 24.1232 2.07354C22.8128 2.07354 21.5864 2.8065 21.0448 
            4.27323C20.4208 2.8065 19.548 2.07354 18.1552 2.07354C16.7832 2.07354 15.7624 2.8065 15.2208 4.27323H15.1784V2.8065C15.1784 
            2.51315 14.9904 2.36689 14.7624 2.36689H12.6848C12.4544 2.36689 12.2688 2.51315 12.2688 2.8065V14.3205C12.2688 14.5404 
            12.4568 14.7602 12.6848 14.7602H14.9104C15.1408 14.7602 15.3264 14.5404 15.3264 14.3205L15.328 8.16027ZM65.9056 14.3205C65.9056 
            14.5404 65.7176 14.7602 65.4896 14.7602H63.264C63.0336 14.7602 62.848 14.5404 62.848 14.3205V2.8065C62.848 2.51315 63.036 2.36689 
            63.264 2.36689H65.3216C65.5616 2.36689 65.7368 2.58669 65.7368 2.66023V4.42031H65.78C66.404 2.88004 67.2768 2.14546 68.8176 2.14546C69.8168 
            2.14546 70.7936 2.51235 71.4176 3.46592C72 4.42031 72 5.95977 72 7.06043V14.3933C71.976 14.6131 71.7936 14.7602 71.5736 14.7602H69.34C69.1328
            14.7602 68.9672 14.6131 68.9432 14.3933L68.9464 7.35377C68.9464 5.007 68.0584 5.007 67.548 5.007C66.9528 5.007 66.4736 5.44662 66.264 5.88704C65.9536 6.62 65.9024 7.28024 65.9024 8.08674L65.9056 14.3205Z"
          fill="#9E9E9E"/>
  </svg>

);

export default AmazonGreyLogo;
