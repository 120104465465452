const CoreIcon = () => (
  <svg
    width="25"
    height="17"
    viewBox="0 0 25 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6726 1.03315L20.8453 8.5L16.6726 15.9668L8.32735 15.9668L4.1547 8.5L8.32735 1.03315L16.6726 1.03315ZM20.0441 12L17.25 17L7.75 17L4.95588 12H0V11H4.39706L3.27941 9H0V8H3.27941L4.39706 6H0V5H4.95588L7.75 0H17.25L20.0441 5L25 5V6L20.6029 6L21.7206 8H25V9H21.7206L20.6029 11L25 11V12L20.0441 12Z"
    />
  </svg>
);

export default CoreIcon;
