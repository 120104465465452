const FolderIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
  
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.50005C1 1.22391 1.22386 1.00005 1.5 1.00005H4.78144C4.91405 1.00005 5.04122 1.05273 5.13499 1.1465L6.89703 2.56064C7.17834 2.84194 7.55987 2.99998 7.95769 2.99998H16.5C16.7761 2.99998 17 3.22384 17 3.49998V5.08545C16.8436 5.03015 16.6753 5.00006 16.4999 5.00006H7.95771C7.8251 5.00006 7.69792 4.94739 7.60415 4.85362L5.84211 3.4394C5.56081 3.1581 5.17928 3.00006 4.78145 3.00006H1.50002C1.32469 3.00006 1.15639 3.03014 1 3.08542V1.50005ZM3.75111e-05 7.00005H0V1.50005C0 0.671626 0.671573 5.34058e-05 1.5 5.34058e-05H4.78144C5.17926 5.34058e-05 5.56079 0.158088 5.8421 0.439393L7.60414 1.85353C7.69791 1.9473 7.82508 1.99998 7.95769 1.99998H16.5C17.3284 1.99998 18 2.67155 18 3.49998V7.00005H17.9999L18 16.5001C18 17.3285 17.3284 18.0001 16.5 18.0001H1.50012C0.671693 18.0001 0.00012207 17.3285 0.00012207 16.5001L3.75111e-05 7.00005ZM1.00002 4.50006C1.00002 4.22392 1.22387 4.00006 1.50002 4.00006H4.78145C4.91406 4.00006 5.04124 4.05274 5.13501 4.14651L6.89705 5.56072C7.17835 5.84203 7.55988 6.00006 7.95771 6.00006H16.4999C16.7761 6.00006 16.9999 6.22392 16.9999 6.50006L17 16.5001C17 16.7762 16.7762 17.0001 16.5 17.0001H1.50012C1.22398 17.0001 1.00012 16.7762 1.00012 16.5001L1.00002 4.50006Z"
     
    />
  </svg>
);

export default FolderIcon;
