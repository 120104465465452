const HrIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_42280_3739)">
      <path d="M19.25 18.75H18V15.625C17.9991 14.7965 17.6695 14.0022 17.0837 13.4163C16.4978 12.8305 15.7035 12.5009 14.875 12.5V11.25C16.0349 11.2513 17.147 11.7126 17.9672 12.5328C18.7874 13.353 19.2487 14.4651 19.25 15.625V18.75Z"  />
      <path d="M14.25 18.75H13V15.625C12.999 14.7965 12.6695 14.0022 12.0836 13.4164C11.4978 12.8305 10.7035 12.501 9.875 12.5H6.125C5.2965 12.501 4.50221 12.8305 3.91637 13.4164C3.33053 14.0022 3.00098 14.7965 3 15.625V18.75H1.75V15.625C1.75136 14.4651 2.21273 13.3531 3.03291 12.5329C3.85308 11.7127 4.96509 11.2514 6.125 11.25H9.875C11.0349 11.2514 12.1469 11.7127 12.9671 12.5329C13.7873 13.3531 14.2486 14.4651 14.25 15.625V18.75Z" />
      <path d="M13 1.25V2.5C13.8288 2.5 14.6237 2.82924 15.2097 3.41529C15.7958 4.00134 16.125 4.7962 16.125 5.625C16.125 6.4538 15.7958 7.24866 15.2097 7.83471C14.6237 8.42076 13.8288 8.75 13 8.75V10C14.1603 10 15.2731 9.53906 16.0936 8.71859C16.9141 7.89812 17.375 6.78532 17.375 5.625C17.375 4.46468 16.9141 3.35188 16.0936 2.53141C15.2731 1.71094 14.1603 1.25 13 1.25Z"/>
      <path d="M8 2.5C8.61807 2.5 9.22226 2.68328 9.73616 3.02666C10.2501 3.37004 10.6506 3.8581 10.8871 4.42911C11.1236 5.00013 11.1855 5.62847 11.065 6.23466C10.9444 6.84085 10.6467 7.39767 10.2097 7.83471C9.77267 8.27175 9.21585 8.56938 8.60966 8.68995C8.00347 8.81053 7.37514 8.74865 6.80412 8.51212C6.2331 8.2756 5.74504 7.87506 5.40166 7.36116C5.05828 6.84725 4.875 6.24307 4.875 5.625C4.875 4.7962 5.20424 4.00134 5.79029 3.41529C6.37634 2.82924 7.1712 2.5 8 2.5ZM8 1.25C7.13471 1.25 6.28885 1.50659 5.56938 1.98732C4.84992 2.46805 4.28916 3.15133 3.95803 3.95076C3.6269 4.75019 3.54026 5.62985 3.70907 6.47852C3.87788 7.32719 4.29456 8.10674 4.90641 8.71859C5.51826 9.33045 6.29782 9.74712 7.14648 9.91594C7.99515 10.0847 8.87482 9.99811 9.67424 9.66697C10.4737 9.33584 11.157 8.77508 11.6377 8.05562C12.1184 7.33615 12.375 6.49029 12.375 5.625C12.375 4.46468 11.9141 3.35188 11.0936 2.53141C10.2731 1.71094 9.16032 1.25 8 1.25Z"/>
    </g>
    <defs>
      <clipPath id="clip0_42280_3739">
        <rect width="20" height="20"  transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>


)
export default HrIcon;
