 const ProductIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_42280_3748)">
      <path d="M9.25 11.25H5.5V12.5H9.25V11.25Z" />
      <path d="M11.75 13.75H5.5V15H11.75V13.75Z" />
      <path d="M16.75 2.5H4.25C3.91861 2.50041 3.6009 2.63224 3.36657 2.86657C3.13224 3.1009 3.00041 3.41861 3 3.75V16.25C3.00041 16.5814 3.13224 16.8991 3.36657 17.1334C3.6009 17.3678 3.91861 17.4996 4.25 17.5H16.75C17.0814 17.4996 17.3991 17.3678 17.6334 17.1334C17.8678 16.8991 17.9996 16.5814 18 16.25V3.75C17.9996 3.41861 17.8678 3.1009 17.6334 2.86657C17.3991 2.63224 17.0814 2.50041 16.75 2.5ZM11.75 3.75V6.25H9.25V3.75H11.75ZM4.25 16.25V3.75H8V7.5H13V3.75H16.75L16.7507 16.25H4.25Z"/>
    </g>
    <defs>
      <clipPath id="clip0_42280_3748">
        <rect width="20" height="20"  transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
)
 export default ProductIcon;
